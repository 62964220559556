var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("نقش ها")]),_c('v-row',{staticClass:"d-flex align-content-start"},[_c('v-col',{staticClass:"px-7"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
                name: 'access-role-create'
              })}}},[_vm._v("افزودن نقش ")])],1),_c('v-col',{staticClass:"px-7"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" افزودن نقش به کاربر ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("ویرایش نقش کاربران")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auto-user',{attrs:{"label":"کاربر را نتخاب کنید","required":""},on:{"change-value":function($event){_vm.userId = $event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-radio-group',{model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}},_vm._l((_vm.role),function(n){return _c('v-radio',{key:n.id,attrs:{"label":("" + (n.name_fa)),"value":n.id}})}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("بستن ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.saveUserRole}},[_vm._v("ذخیره ")])],1)],1)],1)],1)],1),_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-bold text-right"},[_vm._v("#")]),_c('th',{staticClass:"text-bold text-right"},[_vm._v("NAME")]),_c('th',{staticClass:"text-bold text-right"},[_vm._v("عملکردها")]),_c('th',{staticClass:"text-bold text-right"},[_vm._v("عملکرد")])])]),_c('tbody',_vm._l((_vm.role),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name_fa)+" ")]),_c('td',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                      name: 'access-role',
                      params: { id: item.id, name: item.name }
                    })}}},[_c('h5',[_vm._v("کاربران داری این نقش")])])]),_c('td',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                      name: 'access-role-edit',
                      params: { id: item.id }
                    })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pen")])],1)],1)])}),0)]},proxy:true}])})],1),_c('v-card',{staticStyle:{"margin-top":"50px"}},[_c('v-card-title',[_vm._v("دسترسی ها")]),_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-bold text-right"},[_vm._v("#")]),_c('th',{staticClass:"text-bold text-right"},[_vm._v("NAME")]),_c('th',{staticClass:"text-bold text-right"},[_vm._v("عملکردها")])])]),_c('tbody',_vm._l((_vm.permission),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c('a',{on:{"click":function($event){return _vm.$router.push({
                      name: 'access-permission',
                      params: { id: item.id, name: item.name }
                    })}}},[_c('h5',[_vm._v("کاربران داری این نقش")])])])])}),0)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
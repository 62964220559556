























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { AccessApi } from "@/networks/Access";
import store from "@/store";
import UserAutoComplete from "@/views/components/inputs/user.vue";

@Component({
  components: {
    AutoUser: UserAutoComplete
  }
})
export default class Quick extends Vue {
  private role = [];
  private permission = [];
  private dialog = false;
  private userId = 0;
  private roleId = 0;

  mounted() {
    this.roles();
  }

  public async roles(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().getRoles();
      this.role = res.data.data.roles;
      this.permission = res.data.data.permissions;
      console.log(res.data.data);
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async getUserRole(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().getUserRole(this.userId);
      this.roleId = res.data.data.id;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async saveUserRole(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().assignRoleToUser({
        userId: this.userId,
        roleId: this.roleId
      });
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      this.roleId = 0;
      this.userId = 0;
      this.dialog = false;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("userId")
  public onFormChanged() {
    this.getUserRole();
  }
}
